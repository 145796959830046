import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@mui/material"
import Pagination from "@mui/material/Pagination"
import { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useForm, Controller } from "react-hook-form"
import {
  getUsersAPI,
  assignUserToGroupAPI,
  getOrganizationsAPI,
  assignUserRoleAPI,
} from "../../services"
import { useToast } from "../../contexts"
import ClearIcon from "@mui/icons-material/Clear"
import { CustomSelect, CustomTextField } from "./styled"

interface AssignUserDialogProps {
  isOpen: boolean
  onClose: () => void
  selectedGroup: string | undefined
  predefinedUser?: string | undefined
  tabIndex: number
  selectedRole?: string
}

interface IAssignUserForm {
  userSearch: string
  claimRoleType: string[]
  groupRoleType: string
  assignedGroup: string
  systemRole: string
}

export const AssignUserDialog = ({
  isOpen,
  onClose,
  selectedGroup,
  predefinedUser,
  tabIndex,
}: AssignUserDialogProps) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const organizationsQueryParams = {
    includeDisabledGroups: false,
    groupManagementTab: false,
    ownedGroups: true,
  }

  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const { control, reset, setValue, handleSubmit } = useForm<IAssignUserForm>({
    defaultValues: {
      userSearch: "",
      claimRoleType: [],
      groupRoleType: "",
      assignedGroup: "",
      systemRole: "",
    },
  })

  const { data: organizations } = useQuery({
    queryKey: ["organizations-disabled-managed", organizationsQueryParams],
    queryFn: () => getOrganizationsAPI(organizationsQueryParams),
  })

  const [userFilters, setUserFilters] = useState({
    page: 1,
    pageSize: 10,
    searchField: "",
  })
  const [selectedUser, setSelectedUser] = useState<string>("")
  const [selectedUserName, setSelectedUserName] = useState<string>("")
  const [openPopper, setOpenPopper] = useState(false)

  const { data: usersData, isLoading: areAllUsersLoading } = useQuery({
    queryKey: ["users", userFilters.page, userFilters.searchField],
    queryFn: () =>
      getUsersAPI({
        page: userFilters.page,
        searchField: userFilters.searchField,
      }),
  })

  const { mutate: assignUserToGroup, isPending: isAssigningUser } = useMutation(
    {
      mutationFn: (formValues: IAssignUserForm) =>
        assignUserToGroupAPI({
          groupId: selectedGroup! || formValues.assignedGroup,
          userId: selectedUser || predefinedUser!,
          claimRoleTypes: ["Editor"],
          groupRoleType: formValues.groupRoleType,
        }),
      onSuccess: () => {
        toast.show(t("userAssignedSuccessfully"), "success")
        void queryClient.refetchQueries({ queryKey: ["groupUsers"] })

        onClose()
      },
    },
  )

  const { mutate: assignRole, isPending: isAssigningRole } = useMutation({
    mutationFn: (formValues: IAssignUserForm) =>
      assignUserRoleAPI(selectedUser || predefinedUser!, formValues.systemRole),
    onSuccess: (_: IRoleAssignment[], variables: IAssignUserForm) => {
      toast.show(t("systemRoleAssigned"), "success")
      void queryClient.refetchQueries({
        queryKey: ["usersByRole", variables.systemRole],
      })
      onClose()
    },
  })

  const handleUserMenuClose = useCallback(() => {
    setOpenPopper(false)
  }, [])

  const handleUserSelect = useCallback(
    (userId: string, userName: string) => {
      setSelectedUser(userId)
      setSelectedUserName(userName)
      setValue("userSearch", userName)
      handleUserMenuClose()
    },
    [handleUserMenuClose, setValue],
  )

  const onUserPageChange = useCallback((_: unknown, page: number) => {
    setUserFilters((prev) => ({ ...prev, page }))
  }, [])

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchField = e.target.value
      setValue("userSearch", searchField)
      setUserFilters((prev) => ({ ...prev, page: 1, searchField }))
      setOpenPopper(true)
    },
    [setValue],
  )

  const handleClearSearch = useCallback(() => {
    setSelectedUser("")
    setSelectedUserName("")
    setValue("userSearch", "")
    setUserFilters((prev) => ({ ...prev, searchField: "" }))
    setOpenPopper(true)
  }, [setValue])

  const handleAssign = (formValues: IAssignUserForm) => {
    if (tabIndex === 0) {
      assignUserToGroup(formValues)
    } else if (tabIndex === 1) {
      assignRole(formValues)
    }
  }

  useEffect(() => {
    if (!isOpen) {
      handleUserMenuClose()
      setSelectedUser("")
      setSelectedUserName("")
      reset()
    }
  }, [isOpen, handleUserMenuClose, reset])

  return (
    <Dialog
      component="form"
      open={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(handleAssign)}
    >
      <DialogTitle>
        {tabIndex === 0 ? t("assignUser") : t("assignSystemRole")}
      </DialogTitle>
      <DialogContent>
        <ClickAwayListener onClickAway={handleUserMenuClose}>
          <Box display="flex" flexDirection="column" gap="16px" margin="10px">
            {!predefinedUser && (
              <Controller
                name="userSearch"
                control={control}
                rules={{ required: t("required") }}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    {...field}
                    fullWidth
                    error={!!error?.message}
                    helperText={error?.message}
                    label={t("searchUsers")}
                    value={selectedUserName || field.value}
                    onChange={handleSearchChange}
                    onClick={() => setOpenPopper(true)}
                    inputRef={searchInputRef}
                    InputProps={{
                      endAdornment: selectedUserName && (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClearSearch} edge="end">
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            <Popper
              open={openPopper}
              anchorEl={searchInputRef.current}
              placement="bottom-start"
              style={{
                zIndex: 1302,
                minWidth: searchInputRef.current?.offsetWidth,
              }}
            >
              <Paper>
                <Box>
                  {areAllUsersLoading ? (
                    <Box padding="16px" textAlign="center">
                      <CircularProgress />
                    </Box>
                  ) : usersData?.users.length ? (
                    <MenuList>
                      {usersData?.users.map((user) => (
                        <MenuItem
                          key={user.id}
                          onClick={() =>
                            handleUserSelect(
                              user.id,
                              `${user.firstName} ${user.lastName}`,
                            )
                          }
                        >
                          {user.firstName} {user.lastName} ({user.email})
                        </MenuItem>
                      ))}
                    </MenuList>
                  ) : (
                    <Box padding="16px" textAlign="center">
                      {t("noData")}
                    </Box>
                  )}
                  <Box display="flex" justifyContent="center" padding="8px">
                    <Pagination
                      count={Math.ceil(
                        (usersData?.totalCount ?? 0) / userFilters.pageSize,
                      )}
                      page={userFilters.page}
                      onChange={onUserPageChange}
                    />
                  </Box>
                </Box>
              </Paper>
            </Popper>
            {predefinedUser && tabIndex === 0 && (
              <FormControl fullWidth>
                <InputLabel>{t("selectGroup")}</InputLabel>
                <Controller
                  name="assignedGroup"
                  control={control}
                  rules={{ required: t("required") }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        {...field}
                        label={t("selectGroup")}
                        value={field.value}
                        error={!!error?.message}
                        onChange={(event) => {
                          field.onChange(event.target.value)
                        }}
                      >
                        {organizations?.map(
                          (organization) =>
                            organization.groups?.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                {group.name}
                              </MenuItem>
                            )),
                        )}
                      </CustomSelect>
                      <FormHelperText error={!!error?.message}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </FormControl>
            )}

            {tabIndex === 0 && (
              <FormControl fullWidth margin="normal">
                <InputLabel>{t("groupRoleType")}</InputLabel>
                <Controller
                  name="groupRoleType"
                  control={control}
                  rules={{ required: t("required") }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        error={!!error?.message}
                        {...field}
                        label={t("groupRoleType")}
                      >
                        <MenuItem value="GroupOwner">
                          {t("groupOwner")}
                        </MenuItem>
                        <MenuItem value="GroupMember">
                          {t("groupMember")}
                        </MenuItem>
                      </CustomSelect>
                      <FormHelperText error={!!error?.message}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </FormControl>
            )}
            {tabIndex === 1 && (
              <FormControl fullWidth margin="normal">
                <InputLabel>{t("systemRole")}</InputLabel>
                <Controller
                  name="systemRole"
                  control={control}
                  rules={{ required: t("required") }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        error={!!error?.message}
                        {...field}
                        label={t("groupRoleType")}
                      >
                        <MenuItem value="SuperAdmin">
                          {t("superAdmin")}
                        </MenuItem>
                        <MenuItem value="Clerk">{t("clerk")}</MenuItem>
                      </CustomSelect>
                      <FormHelperText error={!!error?.message}>
                        {error?.message}
                      </FormHelperText>
                    </>
                  )}
                />
              </FormControl>
            )}
          </Box>
        </ClickAwayListener>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={tabIndex === 0 ? isAssigningUser : isAssigningRole}
          type="submit"
        >
          {tabIndex === 0 ? (
            isAssigningUser ? (
              <CircularProgress size={24} />
            ) : (
              t("assignUser")
            )
          ) : isAssigningRole ? (
            <CircularProgress size={24} />
          ) : (
            t("assignRole")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
