import { useEffect, useState } from "react"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { updateGroupUserRolesAPI } from "../../services"
import { useToast } from "../../contexts"

interface UpdateUserRolesDialogProps {
  isOpen: boolean
  onClose: () => void
  groupId: string
  userId: string
  userRoles: {
    claimRoles: string[]
    groupRole: string
  }
}

export const UpdateUserRolesDialog = ({
  isOpen,
  onClose,
  groupId,
  userId,
  userRoles,
}: UpdateUserRolesDialogProps) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const [selectedGroupRole, setSelectedGroupRole] = useState<string>("")

  useEffect(() => {
    if (isOpen) {
      setSelectedGroupRole(userRoles.groupRole)
    }
  }, [isOpen, userRoles])

  const { mutate: updateUserRoles, isPending: isUpdating } = useMutation({
    mutationFn: () =>
      updateGroupUserRolesAPI(groupId, userId, ["Editor"], selectedGroupRole),
    onSuccess: () => {
      toast.show(t("userRolesUpdated"), "success")
      void queryClient.refetchQueries({ queryKey: ["groupUsers"] })
      onClose()
    },
    onError: () => {
      toast.show(t("errorUpdatingUserRoles"), "error")
    },
  })

  const handleGroupRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedGroupRole(event.target.value)
  }

  const handleSave = () => {
    updateUserRoles()
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{t("updateUserRoles")}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("groupRole")}</InputLabel>
          <Select
            value={selectedGroupRole}
            onChange={handleGroupRoleChange}
            label={t("groupRole")}
          >
            <MenuItem value="GroupOwner">{t("groupOwner")}</MenuItem>
            <MenuItem value="GroupMember">{t("groupMember")}</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isUpdating}
        >
          {isUpdating ? <CircularProgress size={24} /> : t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateUserRolesDialog
