import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { useCallback, useMemo, useRef } from "react"
import { ContainerBox } from "./styled"
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined"
import {
  checkAllConditionsVisibility,
  colors,
  EURO_SIGN,
  formatIban,
} from "../../../../utils"
import {
  DocumentPreviewDescription,
  FileItem,
  FilePreview,
  FileUpload,
  TextWithLink,
} from "../../../../components"
import WarningIcon from "@mui/icons-material/Warning"
import { Controller } from "react-hook-form"
import { getRules, isFieldRequired } from "../../utils"
import { Trans, useTranslation } from "react-i18next"
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import {
  AutocompleteController,
  PhoneNumberController,
  PlaceController,
  PoliceDepartmentController,
} from "./components"
import { createSearchParams, Link, useNavigate } from "react-router-dom"
import { type ISectionItemProps } from "../../types"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import ErrorIcon from "@mui/icons-material/Error"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

export const SubsectionItem = (props: ISectionItemProps) => {
  const {
    claim,
    claimFiles,
    section,
    subsection,
    formMethods,
    disabled,
    claimDocuments,
    isClaimAutoSaving,
    isUploading,
    liabilityInsuranceCompanyOptions,
    areLiabilityInsuranceCompanyOptionsLoading,
    legalExpensesInsuranceCompanyOptions,
    areLegalExpensesInsuranceCompanyOptionsLoading,
    motorVehicleExpertOptions,
    areMotorVehicleExpertOptionsLoading,
    manufacturersOptions,
    areManufacturersOptionsLoading,
    lessorsLeasingOptions,
    areLessorsLeasingOptionsLoading,
    lessorsFundingOptions,
    areLessorsFundingOptionsLoading,
    selectedSubsections,
    subsectionsWithError,
    isEditModeEnabled,
    isComparisonPreview,
    conflictedSectionIds,
    conflictedColor,
    subsectionsCompleted,
    onSubsectionClick,
    onFieldFocus,
    onFieldBlur,
    onUploadFiles,
    onDeleteFile,
    onFilePreviewClick,
    onRemoveSubsectionClick,
  } = props
  const { t } = useTranslation()
  const { watch, trigger, clearErrors, setError, setValue, control } =
    formMethods

  const navigate = useNavigate()

  const isExpanded =
    !!isComparisonPreview || !!selectedSubsections?.includes(subsection?.id!)

  const inputRef = useRef<HTMLInputElement>(null)

  const onUploadClick = useCallback(() => inputRef?.current?.click(), [])

  const onFilesDrop = useCallback(
    (nextFiles: File[], documentType: string) =>
      onUploadFiles?.([...nextFiles], documentType),
    [],
  )

  const renderField = useCallback(
    (field: IClaimTemplateMetadata, section: string) => {
      const rules = getRules(field, t, watch)

      const isRequired = isFieldRequired(field, t, watch)

      const disabledField = !isComparisonPreview
        ? !isEditModeEnabled
          ? t("editModeNotEnabledTooltip")
          : claim?.disabledValues?.find((val) => val.key === field.controlName)
              ?.value
        : undefined

      const hasPermissionToEdit = claim.permissions?.some(
        (p) =>
          p.action === "NONE" &&
          (p.claimSectionType === section || p.claimSectionType === "None") &&
          (p.access === "Write" || p.access === "Delete"),
      )

      const isDisabled = disabled || !hasPermissionToEdit || !!disabledField

      const label = `${field.label}${isRequired ? "*" : ""}`

      switch (field.fieldType) {
        case "Text":
        case "Number":
        case "Email":
        case "TextArea":
        case "IBAN": {
          const isTextArea = field.fieldType === "TextArea"
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      multiline={isTextArea}
                      rows={4}
                      value={value ?? ""}
                      type={
                        field.fieldType === "IBAN"
                          ? "text"
                          : field.fieldType?.toString()?.toLowerCase()
                      }
                      onFocus={() => {
                        onFieldFocus?.(section)
                      }}
                      onChange={(e) => {
                        let nextValue = e.target.value

                        if (field.rules?.isCapsLocked) {
                          nextValue = nextValue.toUpperCase()
                        }

                        if (!nextValue) {
                          onChange(null)
                        } else if (field.fieldType === "Number") {
                          const nextValueParsed = Number(nextValue)
                          if (
                            typeof nextValueParsed === "number" &&
                            !isNaN(nextValueParsed)
                          ) {
                            onChange(nextValueParsed)
                          } else {
                            onChange(null)
                          }
                        } else if (field.fieldType === "IBAN") {
                          onChange(formatIban(nextValue))
                        } else {
                          onChange(nextValue)
                        }
                      }}
                      onBlur={() => {
                        onBlur()
                        onFieldBlur?.({ controlName: field.controlName, value })
                      }}
                      label={label}
                      disabled={isDisabled}
                      error={!!error?.message}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Checkbox": {
          return (
            <Grid
              id={field.controlName}
              key={field.id}
              item
              xs={12}
              sm={field.sizeInScreen ?? 12}
              md={field.sizeInScreen ?? 12}
              lg={field.sizeInScreen ?? 12}
              xl={field.sizeInScreen ?? 12}
            >
              <Controller
                control={control}
                name={field.controlName}
                rules={rules}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error },
                }) => {
                  const options = field?.selectionOptions?.options?.filter(
                    (o) => {
                      if (o.conditionField) {
                        const val = watch(o.conditionField)
                        return val === o.conditionValue
                      }

                      return true
                    },
                  )

                  return (
                    <>
                      <Box
                        display="flex"
                        flexDirection={options?.length === 1 ? "row" : "column"}
                        alignItems={
                          options?.length === 1 ? "center" : "flex-start"
                        }
                      >
                        {(!!field.label || !!field.rules?.isRequired) &&
                          !!options?.length &&
                          options.length > 1 && (
                            <Typography
                              marginBottom="4px"
                              variant="smallMedium"
                            >
                              {`${field.label || t("selectAnOptionPrompt")}${
                                field.rules?.isRequired ? "*" : ""
                              }`}
                            </Typography>
                          )}
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          flexDirection="row"
                          gap="16px"
                        >
                          {options?.map((option, optionIndex) => {
                            const isSingleChoice =
                              field.selectionOptions
                                .numberOfAllowedSelections === 1

                            const isChecked = isSingleChoice
                              ? value === option.value
                              : !!value?.includes(option.value)

                            const handleChange = (nextValue: any) => {
                              onFieldFocus?.(section)
                              if (isSingleChoice) {
                                const nextMappedValue =
                                  nextValue === value ? null : nextValue

                                onChange(nextMappedValue)
                                onFieldBlur?.({
                                  controlName: field.controlName,
                                  value: nextMappedValue,
                                })
                              } else {
                                const valueArray = value ? value.split(",") : []

                                const index =
                                  valueArray?.findIndex(
                                    (e: any) => e === nextValue,
                                  ) ?? -1

                                let nextValueArray = valueArray

                                if (index !== -1) {
                                  nextValueArray = [
                                    ...valueArray.slice(0, index),
                                    ...valueArray.slice(index + 1),
                                  ]
                                    .sort(undefined)
                                    .join(",")
                                } else {
                                  nextValueArray = [...valueArray, option.value]
                                    .sort(undefined)
                                    .join(",")
                                }

                                const nextMappedValue = nextValueArray || null

                                onChange(nextMappedValue)
                                onFieldBlur?.({
                                  controlName: field.controlName,
                                  value: nextMappedValue,
                                })
                              }

                              void trigger(field.controlName)
                            }

                            const hasDisableCondition =
                              !!option.disabledConditionField

                            let isConditionallyDisabled = false

                            if (hasDisableCondition) {
                              const disableConditionFieldValue = watch(
                                option.disabledConditionField,
                              )

                              if (
                                disableConditionFieldValue ===
                                option.disabledConditionValue
                              ) {
                                isConditionallyDisabled = true
                              }
                            }

                            return (
                              <Box
                                key={`${field.id}-${optionIndex}`}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                              >
                                <Tooltip
                                  title={
                                    disabledField ||
                                    (isConditionallyDisabled
                                      ? option.disabledConditionMessage
                                      : undefined)
                                  }
                                >
                                  <span>
                                    <Checkbox
                                      checked={isChecked}
                                      onChange={() =>
                                        handleChange(option.value)
                                      }
                                      disabled={
                                        isDisabled || isConditionallyDisabled
                                      }
                                      onBlur={onBlur}
                                    />
                                  </span>
                                </Tooltip>
                                <Typography
                                  marginLeft="8px"
                                  color={colors.black}
                                >
                                  {option.label}
                                </Typography>
                              </Box>
                            )
                          })}
                        </Box>
                        {options?.length === 1 && (
                          <Typography marginLeft="8px" variant="small">
                            {field.label}
                          </Typography>
                        )}
                      </Box>
                      {!!error?.message && (
                        <FormHelperText className="Mui-error">
                          {error.message}
                        </FormHelperText>
                      )}
                    </>
                  )
                }}
              />
            </Grid>
          )
        }
        case "Date": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <DatePicker
                      className="width-100"
                      label={label}
                      format="DD.MM.YYYY"
                      value={value ? dayjs(value) : null}
                      onOpen={() => {
                        onFieldFocus?.(section)
                      }}
                      onChange={(value) => {
                        const nextValue = value?.isValid()
                          ? value.format("YYYY-MM-DD")
                          : null
                        onChange(nextValue)
                        onFieldBlur?.({
                          controlName: field.controlName,
                          value: nextValue,
                        })
                      }}
                      onClose={onBlur}
                      disabled={isDisabled}
                      slotProps={{
                        textField: {
                          error: !!error?.message,
                          helperText: error?.message,
                          onBlur,
                        },
                      }}
                      onError={(e) => {
                        if (!e) {
                          clearErrors(field.controlName)
                        } else if (e === "invalidDate") {
                          setError(field.controlName, {
                            message: t("invalidDateFormatErrorMessage"),
                          })
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Time": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({
                    field: { value, onChange, onBlur },
                    fieldState: { error },
                  }) => (
                    <TimePicker
                      className="width-100"
                      label={label}
                      ampm={false}
                      timeSteps={{ minutes: 1 }}
                      value={value ? dayjs(value) : null}
                      onOpen={() => {
                        onFieldFocus?.(section)
                      }}
                      onChange={(value) => {
                        const nextValue = value?.isValid()
                          ? value.format("YYYY-MM-DDTHH:mm:00")
                          : null
                        onChange(nextValue)
                        onFieldBlur?.({
                          controlName: field.controlName,
                          value: nextValue,
                        })
                      }}
                      onClose={onBlur}
                      disabled={isDisabled}
                      slotProps={{
                        textField: {
                          error: !!error?.message,
                          helperText: error?.message,
                          onBlur,
                        },
                      }}
                      onError={(e) => {
                        if (!e) {
                          clearErrors(field.controlName)
                        } else if (e === "invalidDate") {
                          setError(field.controlName, {
                            message: t("invalidTimeFormatErrorMessage"),
                          })
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Insurer": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={liabilityInsuranceCompanyOptions}
                  areOptionsLoading={areLiabilityInsuranceCompanyOptionsLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "InsurerLegalExpenses": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={legalExpensesInsuranceCompanyOptions}
                  areOptionsLoading={
                    areLegalExpensesInsuranceCompanyOptionsLoading
                  }
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "MotorVehicleExpert": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={motorVehicleExpertOptions}
                  areOptionsLoading={areMotorVehicleExpertOptionsLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Salutation": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={
                    field.selectionOptions.options?.map((o) => ({
                      name: o.label,
                      value: o.value,
                    })) ?? []
                  }
                  areOptionsLoading={false}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "ReceivingAuthority": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <PoliceDepartmentController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                  setValue={setValue}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Manufacturer": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={manufacturersOptions}
                  areOptionsLoading={areManufacturersOptionsLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "LessorLeasing": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={lessorsLeasingOptions}
                  areOptionsLoading={areLessorsLeasingOptionsLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "LessorFunding": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <AutocompleteController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  options={lessorsFundingOptions}
                  areOptionsLoading={areLessorsFundingOptionsLoading}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "PhoneNumber": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <PhoneNumberController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "City": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <PlaceController
                  formControl={control}
                  field={{
                    ...field,
                    label,
                  }}
                  disabled={isDisabled}
                  onFieldBlur={onFieldBlur}
                  onFieldFocus={() => {
                    onFieldFocus?.(section)
                  }}
                  rules={rules}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "Currency": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Controller
                  control={control}
                  name={field.controlName}
                  rules={rules}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      fullWidth
                      value={value ?? ""}
                      type="number"
                      onFocus={() => {
                        onFieldFocus?.(section)
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography variant="large">{EURO_SIGN}</Typography>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const nextValue = e.target.value
                        const nextValueParsed = Number(nextValue)
                        if (
                          nextValueParsed &&
                          typeof nextValueParsed === "number" &&
                          !isNaN(nextValueParsed)
                        ) {
                          onChange(nextValueParsed)
                        } else {
                          onChange(null)
                        }
                      }}
                      onBlur={() => {
                        onBlur()
                        onFieldBlur?.({
                          controlName: field.controlName,
                          value,
                        })
                      }}
                      label={label}
                      disabled={isDisabled}
                    />
                  )}
                />
              </Grid>
            </Tooltip>
          )
        }
        case "FileDownload": {
          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <FilePreview
                  fileName={field.label}
                  onPreview={() => {
                    // window.open(
                    //   `${
                    //     window.location.origin
                    //   }/document-preview?${createSearchParams({
                    //     claimId: claim.id,
                    //     documentType: subsection.sectionType,
                    //   }).toString()}`,
                    //   "_blank",
                    // )

                    navigate({
                      pathname: "../document-preview",
                      search: createSearchParams({
                        claimId: claim.id,
                        documentType: subsection?.sectionType!,
                      }).toString(),
                    })
                  }}
                  disabled={isDisabled || !!isClaimAutoSaving}
                />
                {!!isClaimAutoSaving && (
                  <Box display="flex" justifyContent="center" marginTop="4px">
                    <Typography textAlign="center" variant="extraSmallMedium">
                      {`(${t("syncingData")})`}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Tooltip>
          )
        }
        case "FileUpload": {
          const matchingDocument = claimDocuments?.find(
            (doc) => field.documentType === doc.documentType,
          )

          const fieldFiles = matchingDocument?.files ?? []

          const fieldIsRequired = field.rules?.isRequired && !fieldFiles?.length

          return (
            <Tooltip title={disabledField}>
              <Grid
                id={field.controlName}
                key={field.id}
                item
                xs={12}
                sm={field.sizeInScreen ?? 12}
                md={field.sizeInScreen ?? 12}
                lg={field.sizeInScreen ?? 12}
                xl={field.sizeInScreen ?? 12}
              >
                <Box
                  component="input"
                  ref={inputRef}
                  type="file"
                  accept=".doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, application/pdf, .txt, text/plain, image/jpeg, image/jpg, image/png"
                  multiple
                  hidden
                  onChange={(event) => {
                    if (event.target.files?.length) {
                      onFilesDrop(event.target.files as any, field.documentType)
                    }

                    event.target.value = ""
                  }}
                />
                <FileUpload
                  isUploading={isUploading}
                  onUploadClick={onUploadClick}
                  onFilesDrop={(files: File[]) => {
                    onFilesDrop(files, field.documentType)
                  }}
                  disabled={isDisabled}
                  required={fieldIsRequired}
                />
                {!!fieldFiles?.length && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    gap="16px"
                  >
                    {fieldFiles.map((file, index) => (
                      <FileItem
                        key={index}
                        marginTop="16px"
                        file={file}
                        onPreviewClick={() => {
                          onFilePreviewClick?.(file)
                        }}
                        onRemoveClick={() => {
                          onDeleteFile?.(file.id, field.documentType)
                        }}
                        disabled={isDisabled}
                      />
                    ))}
                  </Box>
                )}
                {fieldIsRequired && (
                  <Typography color={colors.red} variant="small">
                    {t("required")}
                  </Typography>
                )}
              </Grid>
            </Tooltip>
          )
        }
        default: {
          return null
        }
      }
    },
    [
      control,
      claim,
      subsection,
      disabled,
      claimDocuments,
      liabilityInsuranceCompanyOptions,
      areLiabilityInsuranceCompanyOptionsLoading,
      legalExpensesInsuranceCompanyOptions,
      areLegalExpensesInsuranceCompanyOptionsLoading,
      motorVehicleExpertOptions,
      areMotorVehicleExpertOptionsLoading,
      manufacturersOptions,
      areManufacturersOptionsLoading,
      lessorsLeasingOptions,
      lessorsFundingOptions,
      areLessorsLeasingOptionsLoading,
      areLessorsFundingOptionsLoading,
      t,
      isUploading,
      isClaimAutoSaving,
      isEditModeEnabled,
      isComparisonPreview,
      onFieldBlur,
      onFieldFocus,
      watch,
    ],
  )

  const magicEmailLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === subsection?.sectionType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "Email",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, subsection])

  const magicSMSLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === subsection?.sectionType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "SMS",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, subsection])

  const shouldDisableDeleteButton = useMemo(
    () =>
      claimFiles?.some(
        (d) => d?.documentType === subsection?.sectionType && !!d.files?.length,
      ),
    [subsection?.sectionType, claimFiles],
  )

  const shouldShowSubsectionDescription = useMemo(
    () =>
      !!subsection?.description &&
      (!subsection?.isDocumentSection ||
        subsection?.sectionType === "DamageDescription"),
    [subsection],
  )

  const shouldShowDocumentExplanation = useMemo(
    () =>
      !!subsection?.isDocumentSection &&
      subsection?.sectionType !== "DamageDescription" &&
      subsection?.sectionType !== "Other",
    [subsection],
  )

  const isSubsectionConflicted = useMemo(
    () =>
      isComparisonPreview && conflictedSectionIds?.includes(subsection?.id!),
    [subsection],
  )

  const hasError = useMemo(
    () => !!subsectionsWithError?.includes(subsection?.id!),
    [subsectionsWithError, subsection],
  )

  const isCompleted = useMemo(
    () => !!subsectionsCompleted?.includes(subsection?.id!),
    [subsectionsWithError, subsection],
  )

  return (
    <ContainerBox expanded={isExpanded}>
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        borderRadius="8px 8px 0px 0px"
        bgcolor={isExpanded ? colors.gray8 : colors.transparent}
        padding="10px 16px"
        className="pointer"
        onClick={() => onSubsectionClick?.(subsection?.id!)}
      >
        <Tooltip title={hasError ? t("sectionErrorTooltip") : null}>
          <Box component="span" marginRight="16px">
            {((!hasError && !isCompleted) || isExpanded) && (
              <Radio
                size="small"
                checked={isExpanded}
                color={
                  isComparisonPreview
                    ? "default"
                    : hasError
                    ? "error"
                    : isCompleted
                    ? "success"
                    : "primary"
                }
              />
            )}
            {hasError && !isExpanded && (
              <ErrorIcon fontSize="small" color="error" />
            )}
            {isCompleted && !isExpanded && (
              <CheckCircleIcon fontSize="small" color="success" />
            )}
          </Box>
        </Tooltip>
        <Box flex={1} display="flex" alignItems="center" gap="6px">
          <Typography variant="smallSemiBold">{subsection!.name}</Typography>
          {!!subsection?.description && !isExpanded && (
            <Tooltip title={subsection.description}>
              <InfoOutlinedIcon fontSize="small" htmlColor={colors.gray3} />
            </Tooltip>
          )}
        </Box>

        {isExpanded ? (
          <ExpandCircleDownOutlinedIcon
            htmlColor={colors.black2}
            sx={{ transform: "rotate(180deg)" }}
          />
        ) : (
          <ExpandCircleDownOutlinedIcon htmlColor={colors.black2} />
        )}
        <IconButton
          sx={{ marginLeft: "8px" }}
          color="error"
          disabled={disabled || shouldDisableDeleteButton}
          onClick={() => onRemoveSubsectionClick?.(subsection!)}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </Box>

      <Collapse
        in={isExpanded}
        timeout="auto"
        // unmountOnExit
      >
        <Box
          padding="16px"
          bgcolor={isSubsectionConflicted ? conflictedColor : "transparent"}
          borderRadius="0px 0px 8px 8px"
        >
          <Grid container spacing="24px">
            {shouldShowSubsectionDescription && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={isComparisonPreview ? 12 : 3}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  // justifyContent="center"
                  // flex={1}
                  // height="100%"
                >
                  {!!subsection?.description && (
                    <Typography variant="small">
                      {subsection?.description}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={
                shouldShowSubsectionDescription && !isComparisonPreview ? 9 : 12
              }
            >
              {shouldShowDocumentExplanation && <DocumentPreviewDescription />}

              {!!magicEmailLinkInfo && (
                <>
                  <Alert
                    severity={
                      dayjs(new Date()).isBefore(
                        new Date(magicEmailLinkInfo.expiresOn),
                      )
                        ? "info"
                        : "warning"
                    }
                  >
                    <Trans
                      i18nKey="emailLinkDescription"
                      values={{
                        expiresOn: dayjs(magicEmailLinkInfo.expiresOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        createdOn: dayjs(magicEmailLinkInfo.createdOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        customer: magicEmailLinkInfo.sentTo,
                      }}
                      components={[
                        <Link
                          key="1"
                          className={disabled ? "disabled" : ""}
                          to={{
                            pathname: "../document-preview",
                            search: createSearchParams({
                              claimId: claim.id,
                              documentType: subsection?.sectionType!,
                            }).toString(),
                          }}
                          state={{
                            isFromLink: true,
                          }}
                        ></Link>,
                      ]}
                    />
                  </Alert>
                </>
              )}

              {!!magicSMSLinkInfo && (
                <>
                  {!!magicEmailLinkInfo && <Box marginTop="8px" />}
                  <Alert
                    severity={
                      dayjs(new Date()).isBefore(
                        new Date(magicSMSLinkInfo.expiresOn),
                      )
                        ? "info"
                        : "warning"
                    }
                  >
                    <Trans
                      i18nKey="smsLinkDescription"
                      values={{
                        expiresOn: dayjs(magicSMSLinkInfo.expiresOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        createdOn: dayjs(magicSMSLinkInfo.createdOn).format(
                          "DD.MM.YYYY HH:mm",
                        ),
                        customer: magicSMSLinkInfo.sentTo,
                      }}
                      components={[
                        <Link
                          key="1"
                          className={disabled ? "disabled" : ""}
                          to={{
                            pathname: "../document-preview",
                            search: createSearchParams({
                              claimId: claim.id,
                              documentType: subsection?.sectionType!,
                            }).toString(),
                          }}
                          state={{
                            isFromLink: true,
                          }}
                        ></Link>,
                      ]}
                    />
                  </Alert>
                </>
              )}

              {(!!magicEmailLinkInfo || !!magicSMSLinkInfo) && (
                <Box marginTop="24px" />
              )}

              {!!subsection?.metadatas?.length && (
                <Box display="flex">
                  <Grid container spacing="16px">
                    {subsection?.metadatas?.map((e) => {
                      const shouldBeVisible = checkAllConditionsVisibility(
                        e.conditions,
                        watch,
                      )

                      if (!shouldBeVisible) {
                        return null
                      }

                      return (
                        <>
                          {!!e.description && (
                            <Grid
                              item
                              marginBottom="-8px"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <TextWithLink text={e.description} />
                            </Grid>
                          )}
                          {!!e.warning && (
                            <Grid
                              item
                              marginBottom="-8px"
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                            >
                              <Box display="flex" alignItems="center">
                                <Typography
                                  paddingRight="8px"
                                  color={colors.red}
                                  variant="small"
                                >
                                  {e.warning}
                                </Typography>
                                <WarningIcon
                                  htmlColor={colors.red}
                                  fontSize="small"
                                />
                              </Box>
                            </Grid>
                          )}

                          {renderField(e, section.sectionType)}
                        </>
                      )
                    })}
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </ContainerBox>
  )
}
