import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import UndoIcon from "@mui/icons-material/Undo"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import SignalCellularNoSimOutlinedIcon from "@mui/icons-material/SignalCellularNoSimOutlined"
import { colors } from "../../../../utils"
import { useCallback, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { deleteClaimAPI, updateStatusAPI } from "../../../../services"
import { useAppContext, useToast } from "../../../../contexts"
import { useNavigate } from "react-router-dom"
import { AlertDialog, ClaimOutOfSyncIcon } from "../../../../components"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { ClaimComparisonModal } from "../ClaimComparisonModal"
import dayjs from "dayjs"

interface IProps {
  claim?: IClaimDetails
  claimTemplate: IClaimTemplate
  isEditMode: boolean
  isClaimLocking: boolean
  hasPermissionToEdit: boolean
  isDisabled: boolean
  hasChangedFields: boolean
  isDirtyAlt: boolean
  isClaimUpdating: boolean
  isAdwovareSyncing: boolean
  onEditClick: (skipSaving?: boolean) => void
  onUndoChangesClick: () => void
  onDiscardChangesClick: () => void
}

export const Header = (props: IProps) => {
  const {
    claim,
    claimTemplate,
    isEditMode,
    isClaimLocking,
    hasPermissionToEdit,
    isDisabled,
    hasChangedFields,
    isDirtyAlt,
    isClaimUpdating,
    isAdwovareSyncing,
    onEditClick,
    onUndoChangesClick,
    onDiscardChangesClick,
  } = props
  const { t } = useTranslation()
  const toast = useToast()
  const { state: appState } = useAppContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const { mutate: deleteClaim, isPending: isClaimDeleting } = useMutation({
    mutationKey: ["delete-claim"],
    mutationFn: () =>
      deleteClaimAPI({
        id: claim?.id!,
        params: { groupId: appState?.groupId! },
      }),
    onMutate: () => {
      setDeleteClaimAlertDialogVisible(false)
    },
    onSuccess: () => {
      toast.show(t("claimDeletedSuccessfullyMessage"), "success")
      if (window.history?.length && window.history.length > 1) {
        navigate(-1)
      } else {
        navigate("../claims", { replace: true })
      }
    },
  })

  const { mutate: closeCase, isPending: isCaseClosing } = useMutation({
    mutationFn: () =>
      updateStatusAPI({
        id: claim?.id!,
        status: "RequestedToClose",
        params: { groupId: appState?.groupId! },
      }),
    onMutate: () => {
      setCloseCaseAlertDialogVisible(false)
    },
    onSuccess: (response) => {
      toast.show(t("claimRequestedToCloseSuccessfullyMessage"), "success")
      queryClient.setQueryData(["claim-details", claim?.id], {
        ...claim,
        status: "RequestedToClose",
        availableStatuses: response,
      })
      if (isEditMode) {
        onEditClick(true)
      }
    },
  })

  const isSwitchDisabled =
    !!claim?.isLocked ||
    isClaimUpdating ||
    isClaimLocking ||
    isClaimDeleting ||
    isAdwovareSyncing ||
    claim?.status === "RequestedToClose"

  const isDeleteDisabled =
    isClaimUpdating || isAdwovareSyncing || isClaimDeleting

  const isCloseDisabled = isClaimUpdating || isAdwovareSyncing || isCaseClosing

  const [isDeleteClaimAlertDialogVisible, setDeleteClaimAlertDialogVisible] =
    useState(false)

  const [isCloseCaseAlertDialogVisible, setCloseCaseAlertDialogVisible] =
    useState(false)

  const [isClaimCollisionModalVisible, setClaimCollisionModalVisible] =
    useState(false)

  const hasClaimConflicts = useMemo(() => {
    return claim?.syncronizationStatus === "OutOfSyncVersionConflict"
  }, [claim])

  const hasPermissionToResolveConflicts = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "RESOLVE_CLAIM" && p.access === "Delete",
      ),
    [user],
  )

  const shouldShowClaimResolveConflictsButton =
    !!hasClaimConflicts && !!hasPermissionToResolveConflicts

  const hasPermissionToDelete = useMemo(() => {
    return (
      !!claim?.permissions?.some(
        (p) => p.action === "DELETE_CLAIM" && p.access === "Delete",
      ) ||
      !!user?.permissions?.some(
        (p) => p.action === "DELETE_CLAIM" && p.access === "Delete",
      )
    )
  }, [claim, user])

  const hasPermissionToClose = useMemo(() => {
    return (
      !!claim?.permissions?.some(
        (p) => p.action === "CLOSE_CLAIM" && p.access === "Delete",
      ) ||
      !!user?.permissions?.some(
        (p) => p.action === "CLOSE_CLAIM" && p.access === "Delete",
      )
    )
  }, [claim, user])

  const onDeleteClaimClick = useCallback(
    () => setDeleteClaimAlertDialogVisible(true),
    [],
  )

  const onDeleteClaimConfirmClick = useCallback(() => {
    deleteClaim()
  }, [])

  const onDeleteClaimCancelClick = useCallback(
    () => setDeleteClaimAlertDialogVisible(false),
    [],
  )

  const onCloseCaseClick = useCallback(
    () => setCloseCaseAlertDialogVisible(true),
    [],
  )

  const onCloseCaseConfirmClick = useCallback(() => {
    closeCase()
  }, [])

  const onCloseCaseCancelClick = useCallback(
    () => setCloseCaseAlertDialogVisible(false),
    [],
  )

  return (
    <Box
      display="flex"
      paddingX="24px"
      paddingY="12px"
      alignItems="center"
      borderBottom={`1px solid ${colors.gray13}`}
    >
      <ClaimComparisonModal
        claimTemplate={claimTemplate}
        claimId={claim?.id!}
        open={isClaimCollisionModalVisible}
        onClose={() => {
          setClaimCollisionModalVisible(false)
        }}
      />
      <AlertDialog
        isVisible={isDeleteClaimAlertDialogVisible}
        message={t("deleteClaimConfirmationMessage")}
        confirmLabel={t("confirmClaimDelete")}
        onConfirm={onDeleteClaimConfirmClick}
        onCancel={onDeleteClaimCancelClick}
      />
      <AlertDialog
        isVisible={isCloseCaseAlertDialogVisible}
        message={t("closeCaseConfirmationMessage")}
        confirmLabel={t("confirmCaseClose")}
        onConfirm={onCloseCaseConfirmClick}
        onCancel={onCloseCaseCancelClick}
      />
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        paddingRight="24px"
      >
        <Box display="flex" alignItems="center" marginBottom="4px">
          <Typography marginRight="8px" variant="largeMedium">
            {t("damageReporting")}
          </Typography>
          {!shouldShowClaimResolveConflictsButton && (
            <Tooltip
              title={
                <Typography color={colors.white}>
                  {t("createClaimDescription")}
                </Typography>
              }
            >
              <InfoOutlinedIcon htmlColor={colors.gray3} />
            </Tooltip>
          )}
          {shouldShowClaimResolveConflictsButton && (
            <Box display="flex" gap="8px" alignItems="center">
              <ClaimOutOfSyncIcon />
              <Button
                variant="outlined"
                onClick={() => setClaimCollisionModalVisible(true)}
              >
                {t("resolve")}
              </Button>
            </Box>
          )}
        </Box>

        <Tooltip
          title={
            claim?.isLocked ? (
              <Typography color={colors.white}>
                {t("lockedClaimTooltip", { user: claim.isLockedBy })}
              </Typography>
            ) : null
          }
        >
          <Box
            display="flex"
            alignItems="center"
            gap="4px"
            marginLeft="-10px"
            marginBottom="4px"
          >
            {isClaimLocking && <CircularProgress size="16px" />}
            <Switch
              value={isEditMode}
              checked={isEditMode}
              onClick={() => onEditClick()}
              disabled={isSwitchDisabled || !hasPermissionToEdit}
            />
            <Typography variant="small">{t("edit")}</Typography>
            {!!claim?.isLocked && (
              <LockOutlinedIcon fontSize="small" htmlColor={colors.gray3} />
            )}
          </Box>
        </Tooltip>

        {!!claim?.caseNumber && (
          <Typography variant="small">
            {t("caseNumber")}:{" "}
            <Typography variant="smallSemiBold">{claim.caseNumber}</Typography>
          </Typography>
        )}

        {!!claim?.createdOn && dayjs(claim.createdOn).isValid() && (
          <Typography variant="small">
            {`${t("createdDate")}: ${dayjs(claim.createdOn).format(
              "DD.MM.YYYY",
            )}`}
          </Typography>
        )}

        {!!claim?.user && (
          <Typography variant="small">
            {(!!claim.user?.firstName || claim.user?.lastName) && (
              <Typography variant="small">
                {`${claim.user.firstName ?? ""} ${
                  claim.user.lastName ?? ""
                }`.trim()}
              </Typography>
            )}
            {!!claim.user.phoneNumber && (
              <Typography marginLeft="8px" variant="small">
                {claim.user.phoneNumber}
              </Typography>
            )}
            {!!claim.user.email && (
              <Typography marginLeft="8px" variant="small">
                {claim.user.email}
              </Typography>
            )}
          </Typography>
        )}
      </Box>

      <Tooltip title={t("undoChanges")}>
        <IconButton
          color="primary"
          onClick={onUndoChangesClick}
          disabled={isDisabled || !hasChangedFields}
        >
          <UndoIcon fontSize="small" htmlColor={colors.black2} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("discardChanges")}>
        <IconButton
          color="primary"
          onClick={onDiscardChangesClick}
          disabled={isDisabled || !isDirtyAlt}
        >
          <CancelOutlinedIcon htmlColor={colors.black2} />
        </IconButton>
      </Tooltip>
      {hasPermissionToDelete && claim?.status === "InCreation" && (
        <Tooltip title={t("deleteClaim")}>
          <IconButton
            color="error"
            onClick={onDeleteClaimClick}
            disabled={isDeleteDisabled}
          >
            <DeleteOutlineIcon />
            {isClaimDeleting && <CircularProgress size="16px" color="error" />}
          </IconButton>
        </Tooltip>
      )}
      {hasPermissionToClose && claim?.status === "Active" && (
        <Tooltip title={t("closeCase")}>
          <IconButton
            color="error"
            onClick={onCloseCaseClick}
            disabled={isCloseDisabled}
          >
            <SignalCellularNoSimOutlinedIcon />
            {isCaseClosing && <CircularProgress size="16px" color="error" />}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
