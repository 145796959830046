import { axios } from "../lib"

export const loginAPI = (body: ILoginRequestBody): Promise<ILoginResponse> =>
  axios.post("Authentication/login", body)

export const refreshTokenAPI = (
  body: IRefreshTokenBody,
): Promise<ILoginResponse> => axios.post("Authentication/refresh-token", body)

export const generateResetPasswordTokenAPI = (
  body: IGenerateResetPasswordTokenBody,
): Promise<void> =>
  axios.post("Authentication/generate-reset-password-token", body)

export const resetPasswordAPI = (
  body: IResetPasswordBody,
): Promise<ILoginResponse> => axios.post("Authentication/reset-password", body)

export const signupAPI = async (body: ISignupRequestBody): Promise<void> =>
  await axios.post("Authentication/register", body)

export const signupOrganizationAPI = async (
  body: ISignupOrganizationRequestBody,
): Promise<void> =>
  await axios.post("Authentication/organization-register", body)

export const changePasswordAPI = (body: IChangePasswordBody): Promise<void> =>
  axios.post("Authentication/change-password", body)

export const verifyEmailAPI = (body: IVerifyEmailBody): Promise<void> =>
  axios.post("Authentication/verify-email", body)

export const resendEmailAPI = (userEmail: string): Promise<void> =>
  axios.post(`Authentication/resend-email?userEmail=${userEmail}`)

export const verifyOtpAPI = (body: IVerifyOtpBody): Promise<void> =>
  axios.post("Authentication/verify-otp", body)

export const resendOtpAPI = (userId: string): Promise<void> =>
  axios.post(`Authentication/resend-otp?userId=${userId}`)
