import { axios } from "../lib"

export const getUserAPI = (): Promise<IUser> => axios.get("User")

export const updateUserAPI = (body: Partial<IUser>): Promise<IUser> =>
  axios.put("User", body)

export const getUsersAPI = (params?: IGetUsersParams): Promise<IUserData> =>
  axios.get("User/users", { params })

export const notifyUserAPI = (params: INotifyUserParams): Promise<void> =>
  axios.post("User/notify-user", null, { params })

export const updateUserProfilePictureAPI = (body: FormData): Promise<IUser> =>
  axios.put("User/profile-picture", body, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const updateUserSignature = (body: FormData): Promise<IUser> =>
  axios.put("User/signature", body, {
    headers: { "Content-Type": "multipart/form-data" },
  })

export const getGroupUsersAPI = (
  params?: IGetGroupUsersParams,
): Promise<IGroupUser[]> => axios.get("GroupUser/groupusers", { params })

export const assignUserToGroupAPI = (body: IAssignGroup): Promise<void> => {
  const { groupId, userId, claimRoleTypes, groupRoleType } = body
  return axios.put(
    `GroupUser/add-user-to-group/${groupId}/${userId}`,
    claimRoleTypes,
    {
      params: {
        groupRoleType,
      },
    },
  )
}

export const deleteUserAPI = (groupId: string, userId: string): Promise<void> =>
  axios.delete(`GroupUser/remove-groupuser/${groupId}/${userId}`)

export const getGroupUserRolesAPI = (
  groupId: string,
  userId: string,
): Promise<string[]> =>
  axios.get(`GroupUser/get-groupuser-roles/${groupId}/${userId}`)

export const updateGroupUserRolesAPI = (
  groupId: string,
  userId: string,
  claimRoleTypes: string[],
  groupRoleType: string,
): Promise<void> => {
  return axios.put(
    `GroupUser/update-groupuser-roles/${groupId}/${userId}`,
    claimRoleTypes,
    {
      params: {
        groupRoleType,
      },
    },
  )
}

export const getUserByRoleAPI = (roleType?: string): Promise<any[]> =>
  axios.get(`Permission/${roleType}/view-users-by-role`, { params: roleType })

export const assignUserRoleAPI = (
  userId: string,
  roleType: string,
): Promise<IRoleAssignment[]> =>
  axios.put(`/Permission/${userId}/add-user-to-role?roleType=${roleType}`)

export const removeUserRoleAPI = (
  userId: string,
  roleType: string,
): Promise<void> =>
  axios.delete(`/Permission/${userId}/remove-user-from-role`, {
    params: { roleType },
  })
