import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { Controller, useForm } from "react-hook-form"
import { useToast } from "../../contexts"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { createGroupAPI } from "../../services"
import {
  CITY_REGEX_VALIDATION,
  NUMERIC_REGEX_VALIDATION,
  URL_REGEX,
} from "../../utils"
import { CustomSelect } from "./styled"

interface CreateGroupModalProps {
  open: boolean
  onClose: () => void
  selectedOrgId?: string
  organizations: Array<{ id: string; name: string }>
}

const CreateGroupModal: React.FC<CreateGroupModalProps> = ({
  open,
  onClose,
  selectedOrgId = "",
  organizations,
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(
    selectedOrgId || "",
  )

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ICreateGroupBody>({
    defaultValues: {
      name: "",
      organizationId: "",
      isEnabled: true,
      externalDetails: {
        addressNr: undefined,
        referat: undefined,
        lawyer: "",
        contactPersonFullName: "",
        contactPersonId: undefined,
      },
      city: null,
      baseUrl: null,
    },
  })

  useEffect(() => {
    if (open) {
      setSelectedOrganizationId(selectedOrgId || "")
      reset({
        name: "",
        organizationId: selectedOrgId || "",
        isEnabled: true,
        externalDetails: {
          addressNr: undefined,
          referat: undefined,
          lawyer: "",
          contactPersonFullName: "",
          contactPersonId: undefined,
        },
        city: null,
        baseUrl: null,
      })
    }
  }, [open, selectedOrgId, reset])

  const { mutate: createGroup, isPending: isCreating } = useMutation({
    mutationFn: (data: ICreateGroupBody) => createGroupAPI(data),
    onSuccess: (newGroup) => {
      toast.show(t("groupCreated"), "success")
      queryClient.setQueryData<IOrganization[]>(
        ["organizations-disabled"],
        (old) => {
          if (!old) return []
          const updatedOrganizations = old.map((org) => {
            if (org.id === selectedOrganizationId) {
              return {
                ...org,
                groups: [...org.groups, newGroup],
              }
            }
            return org
          })

          return updatedOrganizations
        },
      )
      void queryClient.refetchQueries({ queryKey: ["organizations"] })
      onClose()
    },
  })

  const onFormSubmit = (data: ICreateGroupBody) => {
    let baseUrl = data.baseUrl
    if (baseUrl?.endsWith("/")) {
      baseUrl = baseUrl.slice(0, -1)
    }

    const payload: ICreateGroupBody = {
      ...data,
      isEnabled: data.isEnabled,
      organizationId: selectedOrganizationId,
      city: data.city === "" ? null : data.city,
      baseUrl: baseUrl === "" ? null : baseUrl,
    }

    const filteredPayload: ICreateGroupBody = {
      ...payload,
      externalDetails: {
        ...payload.externalDetails,
        contactPersonFullName:
          payload.externalDetails.contactPersonFullName || null,
        contactPersonId: payload.externalDetails.contactPersonId || null,
      },
    }

    createGroup(filteredPayload)
  }

  return (
    <Dialog
      component="form"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <DialogTitle>{t("createGroup")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px" margin="10px">
          <Controller
            name="organizationId"
            control={control}
            rules={{ required: t("required") }}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>{t("selectOrganization")}</InputLabel>
                <CustomSelect
                  {...field}
                  label={t("selectOrganization")}
                  value={selectedOrganizationId}
                  onChange={(e) => {
                    field.onChange(e)
                    setSelectedOrganizationId(e.target.value as any)
                  }}
                >
                  {organizations.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              </FormControl>
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("groupName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.addressNr"
            control={control}
            rules={{
              required: t("required"),
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("addressNr")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.referat"
            control={control}
            rules={{
              required: t("required"),
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("referat")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.lawyer"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("lawyer")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.contactPersonFullName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("contactPersonFullName")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="externalDetails.contactPersonId"
            control={control}
            rules={{
              min: {
                value: 0,
                message: t("minNumberErrorMessage", {
                  value: 0,
                }),
              },
              pattern: {
                value: NUMERIC_REGEX_VALIDATION,
                message: t("onlyDigitsErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="number"
                label={t("contactPersonId")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            rules={{
              pattern: {
                value: CITY_REGEX_VALIDATION,
                message: t("onlyLettersErrorMessage"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("city")}
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
          <Controller
            name="baseUrl"
            control={control}
            rules={{
              pattern: {
                value: URL_REGEX,
                message: t("invalidUrl"),
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label={t("baseUrl")}
                placeholder="https://"
                error={!!error?.message}
                helperText={error?.message}
                fullWidth
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton type="submit" loading={isSubmitting || isCreating}>
          {t("create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateGroupModal
