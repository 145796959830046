import { Box, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IContainerProps {
  expanded: boolean
  preview: boolean
}

export const ContainerBox = styled(Box)<IContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid
    ${(props) =>
      !props.preview && props.expanded ? colors.primary : colors.gray13};
  transition: all 0.2s ease-out;

  pointer-events: ${(props) => (props.preview ? "none" : "auto")};

  &:hover {
    border: 1px solid ${colors.primary};
    scale: ${(props) => (props.expanded ? 1 : 1.01)};
  }

  & .MuiRadio-root {
    padding: 0px !important;
  }
`

export const AddItemContainer = styled(Box)`
  max-width: 450px;
  @media (max-width: 700px) {
    max-width: auto;
  }
`
