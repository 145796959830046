import {
  type Control,
  Controller,
  type FieldValues,
  type RegisterOptions,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Autocomplete, TextField, type TextFieldProps } from "@mui/material"

interface IProps {
  formControl: Control<FieldValues, any>
  field: IClaimTemplateMetadata
  disabled: boolean
  options?: INameValue[]
  areOptionsLoading?: boolean
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
  >
  onFieldBlur?: (field: IChangedField) => void
  onFieldFocus?: () => void
}

export const AutocompleteController = (props: IProps) => {
  const {
    formControl,
    field,
    disabled,
    options,
    areOptionsLoading,
    rules,
    onFieldBlur,
    onFieldFocus,
  } = props
  const { t } = useTranslation()

  return (
    <Controller
      control={formControl}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        let formattedValue

        if (value) {
          formattedValue = options?.find((o) => o.value === value) || value
        }

        return (
          <Autocomplete
            options={options ?? []}
            freeSolo
            disabled={disabled || areOptionsLoading}
            value={formattedValue || ""}
            onInputChange={(event, inputValue) => {
              if (event?.type === "change") {
                onChange(!inputValue ? null : inputValue)
              }
            }}
            onFocus={() => {
              onFieldFocus?.()
            }}
            onBlur={() => {
              onBlur()
              onFieldBlur?.({
                controlName: field.controlName,
                value,
              })
            }}
            onChange={(_e, data) => {
              onChange(data ? (data as INameValue).value : null)
            }}
            loading={areOptionsLoading}
            loadingText={t("loading")}
            noOptionsText={t("noOptions")}
            isOptionEqualToValue={(option, value) =>
              (option as INameValue)?.value === (value as string) ||
              (option as INameValue)?.value === (value as INameValue)?.value
            }
            getOptionLabel={(option) =>
              (option as INameValue)?.name || (option as string)
            }
            renderInput={(params) => (
              <TextField
                {...(params as TextFieldProps)}
                label={field.label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                inputProps={{
                  ...params.inputProps,
                  value: areOptionsLoading
                    ? t("loading")
                    : params.inputProps.value,
                }}
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />
        )
      }}
    />
  )
}
